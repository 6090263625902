<template>
  <v-navigation-drawer
    v-model="$store.state.app.drawer"
    :clipped="$vuetify.breakpoint.lgAndUp"
    app
    width="210"
  >
    <v-list dense>
      <template v-for="item in filteredItems">
        <v-list-group
          v-if="item.children"
          :key="item.text"
          v-model="item.model"
          :prepend-icon="item.model ? item.icon : item['icon-alt']"
          prepend-icon
        >
          <template v-slot:activator>
            <v-list-item>
              <v-list-item-action>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <v-list-item
            v-for="(child, i) in item.children"
            :key="i"
            :to="child.to"
            :active-class="`${themeColor}`"
            class="submenu"
          >
            <v-list-item-action v-if="child.icon">
              <v-icon>{{ child.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ child.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-item
          v-else
          :key="item.text"
          :to="item.to"
          :active-class="`${themeColor}`"
        >
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import lawyer from "../../_mock-data/dropdown/lawyer";
import { UserRole } from "./../../js/constants";

export default {
  data: () => ({
    items: [],
  }),
  created() {
    this.initItems();
  },
  watch: {
    $route: {
      handler(to) {
        this.setVListGroupExpand();
      },
      immediate: true,
    },
    items: {
      handler(to) {
        this.setVListGroupExpand();
      },
    },
  },
  methods: {
    setVListGroupExpand() {
      let targetString = "";
      if (this.$route.matched[0]) {
        targetString = this.$route.matched[0].path;
      }

      this.items.forEach((item) => {
        if (item.children) {
          item.model = false;
          var hasChild = item.children.find((x) => targetString.includes(x.to));

          if (hasChild) {
            item.model = true;
          }
        }
      });
    },
    initItems() {
      this.items = [
        {
          icon: "supervisor_account",
          text: "All Case",
          to: "/searchCase",
          permission: [
            UserRole.ADMIN,
            UserRole.MANAGEMENT,
            UserRole.BACK_OFFICE,
            UserRole.LAWYER,
          ],
        },
        {
          icon: "publish",
          text: "Upload Case",
          to: "/upload-case",
          permission: [UserRole.ADMIN, UserRole.MANAGEMENT],
        },
        {
          icon: "mdi-view-dashboard",
          text: "Dashboard",
          to: "/dashboard",
          permission: [UserRole.ADMIN, UserRole.MANAGEMENT],
        },
        {
          icon: "folder_shared",
          "icon-alt": "keyboard_arrow_down",
          text: "Master Data",
          model: false,
          children: [
            {
              //icon: "assignment_ind",
              text: "Employee",
              to: "/employee",
              permission: [UserRole.ADMIN],
            },

            {
              //icon: "gavel",
              text: "Lawyer",
              to: "/lawyer",
              permission: [UserRole.ADMIN, UserRole.MANAGEMENT],
            },
            {
              //icon: "account_balance",
              text: "Court",
              to: "/court",
              permission: [UserRole.ADMIN, UserRole.MANAGEMENT],
            },
            {
              //icon: "record_voice_over",
              text: "Customer",
              to: "/customer",
              permission: [UserRole.ADMIN, UserRole.MANAGEMENT],
            },
            {
              //icon: "record_voice_over",
              text: "บริษัทขายทอดตลาดรถ",
              to: "/auction-company",
              permission: [UserRole.ADMIN, UserRole.MANAGEMENT],
            },
          ],
        },
        {
          icon: "insert_comment",
          "icon-alt": "keyboard_arrow_down",
          text: "รายงาน",
          model: false,
          children: [
            // {
            //   text: "เก็บเงินลูกค้า",
            //   to: "/billing",
            //   permission: [UserRole.ADMIN, UserRole.BACK_OFFICE],
            // },
            // {
            //   text: "เก็บเงินลูกค้า AEON",
            //   to: "/billing-aeon",
            //   permission: [UserRole.ADMIN, UserRole.MANAGEMENT],
            // },
            {
              text: "จำนวนงานค้าง",
              to: "/report",
              permission: [
                UserRole.ADMIN,
                UserRole.MANAGEMENT,
                UserRole.BACK_OFFICE,
                UserRole.LAWYER,
              ],
            },
          ],
        },
        {
          icon: "mdi-file-excel",
          text: "Remain",
          to: "/remain",
          permission: [UserRole.ADMIN, UserRole.MANAGEMENT],
        },
        {
          icon: "monetization_on",
          "icon-alt": "keyboard_arrow_down",
          text: "เบิกเงิน / จ่ายเงิน",
          model: false,
          children: [
            // {
            //   icon: "note_add",
            //   text: "ทำเรื่องเบิกเงินค่าวิชาชีพ",
            //   to: "/BulkRequestWithdraw",
            //   permission: ["*"]
            // },
            {
              //icon: "list",
              text: "ทำเรื่องถอนเงินทั้งหมด",
              to: "/request-list",
              permission: [
                UserRole.ADMIN,
                UserRole.MANAGEMENT,
                UserRole.BACK_OFFICE,
                UserRole.LAWYER,
              ],
            },
            {
              //icon: "list",
              text: "ใบถอนเงินทั้งหมด",
              to: "/baiberk-maintain",
              permission: [
                UserRole.ADMIN,
                UserRole.MANAGEMENT,
                UserRole.BACK_OFFICE,
                UserRole.LAWYER,
              ],
            },
          ],
        },
      ];

      // let master = this.items.find((x) => x.text.toLowerCase() === "master");
      // master.children.push(
      //   {
      //     //icon: "vpn_key",
      //     text: "Province",
      //     to: "/master-province",
      //     permission: [UserRole.ADMIN],
      //   },
      //   {
      //     //icon: "vpn_key",
      //     text: "District",
      //     to: "/master-district",
      //     permission: [UserRole.ADMIN],
      //   },
      //   {
      //     //icon: "vpn_key",
      //     text: "Sub-district",
      //     to: "/master-subdistrict",
      //     permission: [UserRole.ADMIN],
      //   }
      // );
      // }
    },
    checkPermission(item) {
      let result = this.hasPermission(item.permission);
      return result;
    },
  },
  computed: {
    filteredItems() {
      let resultFilterd = [];

      this.items.forEach((item) => {
        if (!item.hasOwnProperty("children")) {
          if (this.checkPermission(item)) {
            resultFilterd.push(item);
          }
        } else if (item.hasOwnProperty("children")) {
          item.children = item.children.filter((x) => this.checkPermission(x));
          if (item.children.length > 0) {
            resultFilterd.push(item);
          }
        }
      });
      return resultFilterd;

      // let singleLayers = this.items.filter(
      //   (x) => !x.hasOwnProperty("children")
      // );
      // let nestedLayers = this.items.filter(
      //   (x) => x.hasOwnProperty("children") && x.children.length > 0
      // );

      // //single layer
      // let rusultSingle = singleLayers.filter((x) => this.checkPermission(x));
      // resultFilterd.push(...rusultSingle);

      // //nested layer
      // nestedLayers.forEach((item) => {
      //   item.children = item.children.filter((x) => this.checkPermission(x));
      //   if (item.children.length > 0) {
      //     resultFilterd.push(item);
      //   }
      // });
      // return resultFilterd;
    },
  },
};
</script>
